import instagram from "../Assets/instagram.png";
import facebook from "../Assets/facebook.png";
import wp from "../Assets/wp.png";

const Contact = () => {
  return (
    <>
      <div id="contacto">
        <div id="contacto-scroll" className="scroll-fix"></div>
        <h1 className="contact-title">Contactanos</h1>
        <div className="redes-container">
          <div className="wrapper">
            <h1>Podes seguirnos en nuestras redes sociales</h1>
            <a
              href="https://www.instagram.com/reflexologiastrologica/"
              target="_blank"
            >
              <img src={instagram} alt="" />
              @reflexologiastrologica
            </a>
            <a
              href="https://www.facebook.com/reflexoAstrologica?mibextid=qi2Omg&rdid=p7zJFJwItxJQwx1E"
              target="_blank"
            >
              <img src={facebook} alt="" />
              reflexologiastrologica
            </a>
          </div>
        </div>
        <div className="wrapper">
          <div className="wp-info-contact">
            <p>Contactanos a nuestro WhatsApp</p>
            <a
              className="wp-contact-details"
              href="https://api.whatsapp.com/send?phone=541150003793"
              target="_blank"
            >
              <img src={wp} alt="logo de whatsapp" /> +54 1150003793
            </a>{" "}
          </div>
          <div className="info-mail-contact">
            <p>Tambien podes escribirnos a pabloshiatsu@gmail.com</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;

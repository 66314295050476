import Slider from "../Components/Slider";
import Cards from "./Cards";
import AboutUs from "./AboutUs";
import Contact from "./Contact";
import IndividualSessions from "./IndividualSessions";

const Main = () => {
  return (
    <div>
      <Slider />
      <AboutUs />
      <Cards />
      <IndividualSessions />
      <Contact />
    </div>
  );
};

export default Main;

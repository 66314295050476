const aboutUs = () => {
  return (
    <>
      <div id="quienessomos" className="aboutus-container">
        <div id="quienessomos-scroll" className="scroll-fix"></div>
        <h1>Nosotros</h1>
        <div className="wrapper about-us-details">
          <div>
            <p className="about-school">
              <strong>Quinta Esencia Zen</strong> es una Escuela de Reflexologia
              Holística y Astrológica.
            </p>
            <h2>Reconocida por la Asociación Argentina de Reflexólogos.</h2>
          </div>
          <div className="equipment-info">
            <h2>Equipo</h2>
            <div className="equip-details">
              <h3>Pablo Marcelo López</h3>
              <p>Reflexólogo Holístico </p>
              <p>Astrólogo Shiatsuterapeuta</p>
            </div>
            <div className="equip-details">
              <h3>Xiomara Bastidas</h3>
              <p>Reflexologa Holística </p>
              <p>Terapeuta en Masaje Tailandes y Shiatsu</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default aboutUs;

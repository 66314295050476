import Footer from "../Components/Footer";
import HeaderComplementario from "./HeaderComplementario";
import astrologia from "../Assets/formaciones/astrologia.jpeg";
import ScrollToTop from "../Components/ScrollToTop";

const Astrologia = () => {
  return (
    <div>
      <ScrollToTop />
      <HeaderComplementario />
      <div>
        {" "}
        <div className="container-pagelayout wrapper">
          <h1>Astrología con mirada terapéutica</h1>
          <div className="details-pagelayout">
            <div className="page-img">
              <img src={astrologia} alt="" />
            </div>
            <div className="text-pagelayout">
              <p>
                El lenguaje astrológico, mediante su mundo de poderosos símbolos
                proporciona nuevas reformulaciones y comprensiones de lo que es,
                de lo que somos, para acompañar procesos, descubrir potenciales,
                ver alternativas, para ser luego entregado a otros como poderoso
                medio de transformación, o como viaje personal para enriquecer
                la propia vida.
              </p>
              <br />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Astrologia;

import "./App.scss";
import React from "react";
import { Routes, Route } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import Home from "./Components/Home";
import Reflexologia from "./Components/Reflexologia";
import Astrologia from "./Components/Astrologia";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/reflexologia" element={<Reflexologia />} />
          <Route path="/astrologia" element={<Astrologia />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;

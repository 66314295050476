import { Link } from "react-router-dom";
import cerrar from "../Assets/x.png";
import logo from "../Assets/logo-white.png";

const HeaderComplementario = () => {
  return (
    <div className="container-header-complementario">
      <div className="header-complementario-details wrapper">
        <img
          className="logo-principal"
          src={logo}
          alt="logo principal Serena Espacio Holistico"
        />
        <Link to="/">
          <img className="logo-close" src={cerrar} alt="logo close" />
        </Link>
      </div>
    </div>
  );
};

export default HeaderComplementario;

import logobg from "../Assets/logo-white.png";
import wp from "../Assets/wp.png";
import devlogo from "../Assets/logo-dev.png";

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="footer-details wrapper">
        <a href="#">
          <img
            src={logobg}
            className="logo-principal"
            alt="Logo principal de cema arquitectura"
          />
        </a>

        <div className="info-footer">
          <p> Villa Pueyrredón, CABA</p>
          <p>Lunes a viernes de 9 a 18hs </p>
        </div>

        <div className="info-footer">
          <p>pabloshiatsu@gmail.com</p>
          <div className="wp-container">
            <a
              href="https://api.whatsapp.com/send?phone=541150003793"
              target="_blank"
            >
              <img className="wp-contact" src={wp} alt="logo de whatsapp" /> +54
              1150003793
            </a>{" "}
          </div>
        </div>

        <div className="info-footer site-info">
          <p>Sitio:</p>
          <a href="https://athom.com.ar/" target="_blank">
            <img className="logo-developer" src={devlogo} alt="Logo de Athom" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;

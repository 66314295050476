import { Link } from "react-router-dom";

const Cards = () => {
  return (
    <div id="formaciones" className="cards-container wrapper">
      <div id="formaciones-scroll" className="scroll-fix"></div>
      <Link to="/reflexologia" className="card-info card-1">
        <h1>Reflexología astrológica</h1>
      </Link>
      <Link to="/astrologia" className="card-info card-2">
        <h1>Astrología con mirada terapéutica</h1>
      </Link>
    </div>
  );
};

export default Cards;

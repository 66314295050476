import Footer from "../Components/Footer";
import HeaderComplementario from "./HeaderComplementario";
import reflexologia from "../Assets/formaciones/reflexologia.jpeg";
import ScrollToTop from "../Components/ScrollToTop";

const Reflexologia = () => {
  return (
    <div>
      <ScrollToTop />
      <HeaderComplementario />
      <div>
        {" "}
        <div className="container-pagelayout wrapper">
          <h1>Reflexología astrológica</h1>
          <div className="details-pagelayout">
            <div className="page-img">
              <img src={reflexologia} alt="" />
            </div>
            <div className="text-pagelayout">
              <p>
                La Reflexología tradicional encuentra en los pies el sistema
                orgánico de cada uno de nosotros. La Reflexología
                Holística/Astrológica encuentra, a través del contacto sensible
                y profundo en los pies, aspectos energéticos, orgánicos,
                psíquicos, rítmicos, vinculares... tomando los pies como comando
                general de la nave que somos.
              </p>{" "}
              <br />
              <p>
                El ser humano entendido como una globalidad. Cuando contactamos
                con la sensibilidad/profundidad que hay en los pies aparecen
                imágenes, signos, dolores, placeres, recuerdos, ideas, deseos,
                pensamientos, sensaciones...
              </p>
              <br />
              <br />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Reflexologia;

const IndividualSessions = () => {
  return (
    <>
      <div id="sesiones" className="individual-sesions-container">
        <div id="sesiones-scroll" className="scroll-fix"></div>
        <div className="wrapper">
          <h1 className="sesions-title">Sesiones Individuales</h1>
        </div>
        <div className="wrapper services-container">
          <div>
            <div className="info-sessions">
              <h1>Reflexología</h1>
            </div>

            <h2>
              Encuentro de una hora en donde conectamos con tus pies utilizando
              diferentes técnicas y toques sobre las áreas reflejas y desde este
              contacto acompañamos procesos, promoviendo la relajación profunda
              y el bienestar.
            </h2>
          </div>
          <div>
            <div className="info-sessions">
              <h1>Shiatsu</h1>
            </div>
            <div>
              <h2>
                <strong>Masaje Japonés</strong> <br />
                Técnica de origen oriental que se aplica con las manos
                presionando sobre un mapa de puntos energéticos dando espacio al
                cuerpo y a la mente a la renovación y el descanso.
              </h2>
            </div>
          </div>

          <div>
            <div className="info-sessions">
              <h1>Astrología </h1>
            </div>

            <h2>
              <strong>Lectura del código natal.</strong> <br />
              Encuentros de desarrollo personal, profundizando en el mapa
              personal.
            </h2>
          </div>
          <div>
            <div className="info-sessions">
              <h1>Constelaciones familiares </h1>
            </div>

            <h2>
              Consulta individual desde la mirada de las constelaciones
              familiares. Un encuentro para trabajar un tema, salud, trabajo,
              vínculos, desde una mirada integral que incluye a nuestro sistema
              familiar.
            </h2>
          </div>
        </div>
      </div>
      <div className="line wrapper"></div>
    </>
  );
};

export default IndividualSessions;

import React, { Component } from "react";
import Slider from "react-slick";
import rpa1 from "../Assets/slider/1.jpg";
import rpa2 from "../Assets/slider/2.jpg";
import rpa3 from "../Assets/slider/3.jpg";
/* 
import rpa1responsive from "../Assets/slider/1responsive.jpg";
import rpa2responsive from "../Assets/slider/2responsive.png";
import rpa3responsive from "../Assets/slider/3responsive.png"; */

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default class AutoPlay extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      speed: 2000,
      autoplaySpeed: 2000,
    };

    return (
      <div id="news" className="container-carrousel-slider">
        <div className="slider-container">
          <Slider {...settings}>
            <div className="wrapper">
              <img className="slider-img" src={rpa1} alt="" />
            </div>
            <div className="wrapper">
              <img className="slider-img" src={rpa2} alt="" />
            </div>
            <div className="wrapper">
              <img className="slider-img" src={rpa3} alt="" />
            </div>
          </Slider>
        </div>

        {/* <div className="slider-container-responsive">
          <Slider {...settings}>
            <div className="wrapper">
              <img
                className="slider-img-responsive"
                src={rpa1responsive}
                alt=""
              />
            </div>
            <div className="wrapper">
              <img
                className="slider-img-responsive"
                src={rpa2responsive}
                alt=""
              />
            </div>
            <div className="wrapper">
              <img
                className="slider-img-responsive"
                src={rpa3responsive}
                alt=""
              />
            </div>
          </Slider>
        </div> */}
      </div>
    );
  }
}
